import { useField } from "formik";
import React, { InputHTMLAttributes } from "react";

type InputFieldProps = InputHTMLAttributes<HTMLInputElement> & {
  className?: string;
  name: string;
  label?: string;
};

export const InputField: React.FC<InputFieldProps> = ({
  className,
  label,
  size: _,
  ...props
}) => {
  const [field] = useField(props);

  return (
    <div className="text-white w-full font-lucida-grande">
      {label && <label htmlFor={field.name}>{label}</label>}
      <input
        className={`border-b text-center placeholder:text-white placeholder:capitalize w-full py-1 px-3 md:px-4 text-md md:text-lg bg-transparent ${className}`}
        {...field}
        {...props}
        id={field.name}
        placeholder={props.placeholder}
      />
    </div>
  );
};
