import Swal from "sweetalert2";

export function NoFilesError() {
  Swal.fire({
    text: "Error, please add title(s) of audio/video",
    icon: "error",
    iconColor: "#cf152d",
    confirmButtonColor: "#cf152d",
  });
}

export function GeneralError({ text }: any) {
  Swal.fire({
    text: text ? text : "Oops, there something wrong",
    icon: "error",
    iconColor: "#cf152d",
    confirmButtonColor: "#cf152d",
  });
}

export function Success() {
  Swal.fire({
    text: "Success, files are submitted!",
    icon: "success",
  });
}

export function SubscriptionSubmitted({ text }: any, reload: boolean) {
  Swal.fire({
    text,
    icon: "success",
  }).then((result) => {
    if (reload) {
      window.location.reload();
    }
  });
}
