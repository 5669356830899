import {
  Box,
  // Button,
  // Heading,
  // Image,
} from "../../../components/StyledComponent";
import {
  // faPause,
  // faPlay,
  faVolumeHigh,
  faVolumeMute,
  // faVolumeOff,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RoutePattern } from "../../../routes/RoutePattern";
import VIDEO1 from "../../../assets/videos/rocnation_january_10th_2023 mobile.mp4";
import ROC_LOGO from "../../../assets/images/rn_logo_white.png";
import POSTER from "../../../assets/images/poster.png";
import styled from "styled-components";
import { Form, Formik } from "formik";
import { InputField } from "../../../components/StyledComponent/InputField";
import SubscriptionController, {
  error,
} from "../../../api/Controller/SubcriptionController";
import {
  GeneralError,
  SubscriptionSubmitted,
  Success,
} from "../../../api/Controller/GSheetAPIController/Responds";
import Button from "../../../components/Form/Button";
import useSpreadSheet from "../../Hooks/useSpreadSheet";
// import { isMobile } from "../../../constants";

const VolumeBtn = styled.button`
  bottom: 7%;
  @media (max-width: 821px) {
    bottom: 14%;
  }
  
  @media (max-width: 1181px) and (max-height: 821px) {
    bottom: 13%;
  }
`;

const VideoPlayer = styled.video`
`;

const VideoContainer = styled.div`
  @media (max-width: 820px) {
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    position: absolute;
  }
`;

const ImgLogo = styled.img`
  width: 25%;
  margin: auto;

  @media (max-width: 1180px) {
    width: 35%;
  }

  @media (max-width: 845px) {
    width: 50%;
  }

  @media (max-width: 845px) and (max-height: 390px) {
    width: 25%;
    margin-top: 8rem;
  }
`;

const EmailCapture = styled.div`
`;

const Showcase = () => {
  const [videoEnded, setVideoEnded] = useState<boolean>(false);
  const [muted, setMuted] = useState<boolean>(true);
  const [showModal, setShowModal] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const videoRef: any = useRef(null);
  const { get } = useSpreadSheet();

  // this handles if video is ended
  useEffect(() => {
    const video = videoRef.current as HTMLVideoElement;

    if (videoEnded) {
      setShowModal(true);
    }
  }, [videoEnded]);

  function handleClick(value: boolean) {
    const video = videoRef.current as HTMLVideoElement;

    if (value) {
      video.play();
      video.muted = false;
    } else {
      // simply pauses the video
      video.pause();
    }
  }
  
  return (
    <Box
      id={RoutePattern.HOME}
      className="relative flex flex-col justify-center items-center h-screen w-full overflow-hidden py-10"
    >
      <Box className="z-30 flex flex-col justify-center items-center h-full w-full">
        <VideoContainer
          className="h-auto lg:h-full w-full align-center"
        >
          <ImgLogo src={ROC_LOGO}></ImgLogo>
          <VideoPlayer
            id="video_player"
            ref={videoRef}
            preload="metadata"
            playsInline
            autoPlay
            muted
            poster={POSTER}
            src={VIDEO1}
            onEnded={() => setVideoEnded(true)}
            className="h-auto md:h-1/2 lg:h-2/3 xl:h-3/4 w-full object-contain block object-center"
          ></VideoPlayer>
          <EmailCapture>
            <Formik
              initialValues={{ Sheet_Name: "Sheet2", Email_Address: "", Best_All_Around: "", Most_Likely_to_Succeed: "", Most_Unforgettable: "", Best_Dressed: "", Most_Likely_to_be_MCM_MCW: "", Most_Likely_to_Get_a_Star_on_the_Hollywood_Walk_of_Fame: "" }}
              onSubmit={async (values, set) => {
                const controller = SubscriptionController();
                const isSubmitted = await controller.submitForm({
                  ...values,
                });
  
                if (isSubmitted) {
                  setUserEmail(values.Email_Address);
                  set.resetForm();
                  SubscriptionSubmitted({
                    text: "Thanks! Your submission has been received.",
                  }, false);
                } else {
                  GeneralError({ text: "" });
                }
              }}
            >
              {({ isSubmitting }) => (
                <Form className="flex flex-col justify-center items-center text-center">
                  <InputField type="email" name="Email_Address" placeholder="Enter your email to get more updates" autoComplete="off" className="mt-5 px-1 w-2/3 md:w-1/4 m-auto" style={{fontFamily: "Bebas Neue", color: "#000", backgroundColor: "#fff"}} />
                  <div className="mt-5">
                    <Button isSubmitting={isSubmitting} type="submit">
                      <span className="m-auto">Submit</span>
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </EmailCapture>
        </VideoContainer>
      </Box>

      <Box>
        {videoEnded ? null : muted ? (
          <VolumeBtn
            className="absolute left-2 md:left-5 border border-gray-500 block w-7 h-7 text-sm md:w-16 md:h-16 md:text-lg text-white hover:text-black rounded-full hover:border-white hover:shadow-md z-50"
            onClick={() => {
              setMuted(false);
              videoRef.current.muted = false;
              if (videoRef.current.paused) {
                videoRef.current.play();
              }
            }}
          >
            <span className="bg-gray-500 bg-opacity-50 mx-auto rounded-full w-5/6 h-5/6 flex hover:bg-white">
              <FontAwesomeIcon icon={faVolumeMute as any} className="m-auto" />
            </span>
          </VolumeBtn>
        ) : (
          <VolumeBtn
            className="absolute left-2 md:left-5 border border-gray-500 block w-7 h-7 text-sm md:w-16 md:h-16 md:text-lg text-white hover:text-black rounded-full hover:border-white hover:shadow-md z-50"
            onClick={() => {
              setMuted(true);
              videoRef.current.muted = true;
              // videoRef.current.pause();
            }}
          >
            <span className="bg-gray-500 bg-opacity-50 mx-auto rounded-full w-5/6 h-5/6 flex hover:bg-white">
              <FontAwesomeIcon icon={faVolumeHigh as any} className="m-auto" />
            </span>
          </VolumeBtn>
        )}

        {/* {videoEnded ? null : (
          <VolumeBtn
            className="absolute right-2 md:right-5 block w-7 h-7 text-sm md:w-16 md:h-16 md:text-lg text-white hover:text-primary rounded-full hover:border-white hover:shadow-md z-50"
            onClick={() => {
              setVideoEnded(true);
            }}
          >
            Skip
          </VolumeBtn>
        )} */}
      </Box>

      {showModal ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black"
          >
            <div className="relative w-screen lg:w-1/3 px-2 my-6 mx-auto">
              {/*content*/}
              <div className="animate-mount shadow-lg relative flex flex-col w-full bg-black outline-none focus:outline-none">
                {/*body*/}
                <div className="relative flex-auto">
                  <Formik
                    initialValues={{ Sheet_Name: "Sheet1", Email_Address: userEmail, Best_All_Around: "", Most_Likely_to_Succeed: "", Most_Unforgettable: "", Best_Dressed: "", Most_Likely_to_be_MCM_MCW: "", Most_Likely_to_Get_a_Star_on_the_Hollywood_Walk_of_Fame: "" }}
                    onSubmit={async (values, set) => {
                      // check if values already existed
                      // const sheets = await get();
                      // const included = sheets.filter((row: any) =>
                      //   row.includes(values.Email_Address)
                      // );
        
                      // if (included.length) {
                      //   GeneralError({
                      //     text: "Your email has already been used.",
                      //   });
                      //   return;
                      // }

                      const controller = SubscriptionController();
                      const isSubmitted = await controller.submitForm({
                        ...values,
                      });

                      if (isSubmitted) {
                        set.resetForm();
                        SubscriptionSubmitted({
                          text: "Thanks! Your submission has been received.",
                        }, true);
                      } else {
                        GeneralError({ text: "" });
                      }
                    }}
                  >
                    {({ isSubmitting }) => (
                      <Form className="flex flex-col justify-center items-center">
                        <div className="text-center mb-5 text-4xl">VOTE NOW</div>
                        <div className="border-2 rounded-lg w-full px-3 py-6 lg:px-6">
                          <InputField type="hidden" name="Email_Address" value={userEmail} />
                          <InputField name="Best_All_Around" placeholder="Best all-around" autoComplete="off" style={{fontFamily: "Bebas Neue"}} />
                          <InputField name="Most_Likely_to_Succeed" placeholder="Most likely to succeed" autoComplete="off" className="mt-5" style={{fontFamily: "Bebas Neue"}} />
                          <InputField name="Most_Unforgettable" placeholder="Most unforgettable" autoComplete="off" className="mt-5" style={{fontFamily: "Bebas Neue"}} />
                          <InputField name="Best_Dressed" placeholder="Best dressed" autoComplete="off" className="mt-5" style={{fontFamily: "Bebas Neue"}} />
                          <InputField name="Most_Likely_to_be_MCM_MCW" placeholder="Most likely to be MCM/WCW" autoComplete="off" className="mt-5" style={{fontFamily: "Bebas Neue"}} />
                          <InputField name="Most_Likely_to_Get_a_Star_on_the_Hollywood_Walk_of_Fame" placeholder="Most likely to get a star on the Hollywood Walk of Fame" title="Most likely to get a star on the Hollywood Walk of Fame" autoComplete="off" className="mt-5" style={{fontFamily: "Bebas Neue"}} />
                        </div>
                        <div className="mt-5">
                          <Button isSubmitting={isSubmitting} type="submit">
                            <span className="m-auto">Submit</span>
                          </Button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </Box>
  );
};

export default Showcase;
