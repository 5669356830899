import $ from "jquery";
import Swal from "sweetalert2";

export interface SubscriptionControllerProps {
  Best_All_Around: string;
  Most_Likely_to_Succeed: string;
  Most_Unforgettable: string;
  Best_Dressed: string;
  Most_Likely_to_be_MCM_MCW: string;
  Most_Likely_to_Get_a_Star_on_the_Hollywood_Walk_of_Fame: string;
  Email_Address: string;
  Sheet_Name: string;
}

export default function SubscriptionController() {
  async function _submitForm(value: SubscriptionControllerProps) {
    const endpoint =
      "https://script.google.com/macros/s/AKfycbxvT6WlKwp0Xk1UOGRQtn1xqLTlH2Ythf53qngsG1HDJp1j3fIbahtJ2fP2RXy3FFRppQ/exec";
      // "https://script.google.com/macros/s/AKfycbwQ74al9DE3Eyuky0ZBaMVUgkINO1lEo1uE5r-jtzkQMhPAerNbP_5s1xqhreQkBqy_nQ/exec";

    // if (!value.Email_Address) {
    //   return false;
    // }

    if (value.Sheet_Name == "Sheet1") {
      if (!value.Best_All_Around) {
        return false;
      }

      if (!value.Most_Likely_to_Succeed) {
        return false;
      }

      if (!value.Most_Unforgettable) {
        return false;
      }

      if (!value.Best_Dressed) {
        return false;
      }

      if (!value.Most_Likely_to_be_MCM_MCW) {
        return false;
      }

      if (!value.Most_Likely_to_Get_a_Star_on_the_Hollywood_Walk_of_Fame) {
        return false;
      }
    }

    const submitted = await $.ajax(endpoint, {
      data: {
        Sheet_Name: value.Sheet_Name,
        Date: getCurrentDate(),
        Email_Address: value.Email_Address,
        Best_All_Around: value.Best_All_Around,
        Most_Likely_to_Succeed: value.Most_Likely_to_Succeed,
        Most_Unforgettable: value.Most_Unforgettable,
        Best_Dressed: value.Best_Dressed,
        Most_Likely_to_be_MCM_MCW: value.Most_Likely_to_be_MCM_MCW,
        Most_Likely_to_Get_a_Star_on_the_Hollywood_Walk_of_Fame: value.Most_Likely_to_Get_a_Star_on_the_Hollywood_Walk_of_Fame,
      },
    });

    return submitted.result === "success";
  }

  function getCurrentDate() {
    const date = new Date().toLocaleDateString("en-US", {
      timeZone: "America/New_York",
    });
    const time = new Date().toLocaleTimeString("en-US", {
      timeZone: "America/New_York",
    });
    return `${date} - ${time} (GMT-4)`;
  }

  async function getUsersIp() {
    try {
      let ip: string = await $.get(
        "https://api.ipify.org/?format=json",
        (data: any) => {
          ip = data.ip;
        }
      );
      return ip;
    } catch (error) {
      return "Error IP";
    }
  }

  return {
    submitForm(values: SubscriptionControllerProps) {
      return _submitForm(values);
    },
  };
}

export function sucess() {
  console.log("success");
  Swal.fire({
    icon: "success",
    titleText: "Success",
  });
}

export function error() {
  console.log("error");
  Swal.fire({
    icon: "error",
    titleText: "Error!",
  });
}
